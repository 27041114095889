.PrivatePage {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &_content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: scroll;
  }
}
