.PrivatePageSideBar {
  min-width: 256px;
  height: 100%;
  text-align: left;
  background-color: #f0f0f0;

  .ant-menu {
    height: 100%;
  }
}

.PrivatePageSideBar_footer {
  border-bottom-color: rgba(5, 5, 5, 0.06);
  border-bottom-style: solid;
  border-right-color: rgba(5, 5, 5, 0.06);
  border-right-style: solid;
  border-bottom-width: 1px;
  border-right-width: 1px;
  background-color: #ffffff;
  text-align: center;
  position: sticky;
  padding: 10px;
  bottom: 0;
}
